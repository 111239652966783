<template>
  <div class="text-center">
    <div id="ticketingMap" />
    <v-dialog
      v-model="ticketing.viewTicketingDetailTabs"
      width="1600"
      persistent
    >
      <v-card>
        <div class="text-right mr-1 mb-2">
          <v-icon
            color="red"
            size="28"
            @click="closeAll()"
          >
            mdi-close
          </v-icon>
        </div>
        <v-tabs
          v-model="tab"
          background-color="#0da344"
          dark
          show-arrows
          class="fixed-tabs-bar"
        >
          <v-tab
            v-for="(item, i) in requests"
            :key="i"
          >
            Request n. {{ item.request_count }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(item, i) in requests"
            :key="i"
          >
            <ticketing-details
              v-if="tab === i"
              :key="renderTab"
              :request="item"
              :is-tabs="true"
              :filter-range-date="filterRangeDate"
              @remove-tab="closeTab($event)"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    TicketingDetails: () =>
      import('@/components/services/TicketingDetails')
  },
  props: {
    requests: {
      type: Array,
      default: null
    },
    filterRangeDate: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tab: null,
      renderTab: true
    };
  },
  computed: {
    ...mapState(['ticketing'])
  },
  watch: {
  },
  mounted() {
    this.requests.forEach(item => {
      this.ticketing.tempRangeDate[`req${item.request_number}`] = '';
      this.ticketing.tempMeteoType[`req${item.request_number}`] = '';
      this.ticketing.tempDocType[`req${item.request_number}`] = '';
      this.ticketing.tempAddressType[`req${item.request_number}`] = '';
      this.ticketing.tempSendMail[`req${item.request_number}`] = '';
      this.ticketing.tempEvasionLimit[`req${item.request_number}`] = '';
      this.ticketing.tempLocations[`req${item.request_number}`] = [];
      this.ticketing.tempLocationsToRemove[`req${item.request_number}`] = [];
      this.ticketing.tempDocS3Radarmeteo[`req${item.request_number}`] = [];
    });
  },
  methods: {
    ...mapActions([]),
    closeTab(item) {
      this.$emit('remove-tab', item.request_number);
      delete this.ticketing.tempRangeDate[`req${item.request_number}`];
      delete this.ticketing.tempMeteoType[`req${item.request_number}`];
      delete this.ticketing.tempDocType[`req${item.request_number}`];
      delete this.ticketing.tempAddressType[`req${item.request_number}`];
      delete this.ticketing.tempSendMail[`req${item.request_number}`];
      delete this.ticketing.tempLocations[`req${item.request_number}`];
      delete this.ticketing.tempLocationsToRemove[`req${item.request_number}`];
      delete this.ticketing.tempDocS3Radarmeteo[`req${item.request_number}`];
      delete this.ticketing.tempEvasionLimit[`req${item.request_number}`];
      this.renderTab = false;
      this.$nextTick(() => {
        this.renderTab = true;
      });
    },
    closeAll() {
      this.$emit('close-all-tabs');
      this.ticketing.viewTicketingDetailTabs = false;
      this.ticketing.tempRangeDate = {},
      this.ticketing.tempMeteoType = {},
      this.ticketing.tempDocType = {},
      this.ticketing.tempAddressType = {},
      this.ticketing.tempSendMail = {},
      this.ticketing.tempLocations = {},
      this.ticketing.tempLocationsToRemove = {},
      this.ticketing.tempDocS3Radarmeteo = {};
      this.ticketing.tempEvasionLimit = {};
    }
  }
};
</script>

<style scoped>
.fixed-tabs-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 2;
}
</style>
